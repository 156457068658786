import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ManualiService } from 'src/app/shared/service/manuali.service';


@Component({
  selector: 'app-manuali',
  templateUrl: './manuali.component.html',
  styleUrls: ['./manuali.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManualiComponent implements OnInit {

  pages: any[] = [];
  single_page:any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private manualiService: ManualiService
  ) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
    // this.route.queryParams.subscribe(params => {
    var params = this.route.snapshot.params;
      if(params.id) {
        this.pages = [];
        this.manualiService.GetPage(params.id).subscribe((x: any) => {
          this.single_page = x;
        })
      }
      else {
        this.single_page = null;
        this.manualiService.GetPages().subscribe((x: any) => {
          this.pages = x.data;
        })
      }
    // })
    
  }
  /**
   * GoToPage
   */
  public GoToPage(x: any) {
    this.router.navigate(['/manuali', x.id ]);
    
  }

  screenshots_a = [
    { img: "assets/images/ss/a_01.jpg" },
    { img: "assets/images/ss/a_02.jpg" },
    { img: "assets/images/ss/a_03.jpg" },
    { img: "assets/images/ss/a_04.jpg" },
  ]

  screenshots_d = [
    { img: "assets/images/ss/d_01.png" },
    { img: "assets/images/ss/d_02.png" },
    { img: "assets/images/ss/d_03.png" },
    { img: "assets/images/ss/d_04.png" },
  ]
  screenshotscarouselOptions_d = {
    items: 1,
    margin: 55,
    autoHeight: true,
    nav: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        360: {
            items: 2,
            margin: 10
        },
        480: {
            margin: 15
        },
        576: {
            margin: 15
        },
        1000: {
            items: 1
        }
    }
  }
}
