<!-- Nav Start-->
<header class="app2 loding-header nav-abs custom-scroll">
  <div class="animated-bg"><i></i><i></i><i></i></div>
  <div class="container">
    <div class="row">
      <div class="col">
        <nav>
          <!-- <app-menu></app-menu> -->
          <div class="logo"></div>
              <!-- <a href="#" class="d-inline-block">
                
                <span class="iltuoconta_text">Il tuo Conta</span>
              
              </a> -->
              <span class="iltuoconta_text">Il tuo Conta</span>
            
          </nav>
          </div>
          <div class="col">
            
          </div>
      </div>
  </div>
</header>
<style>

</style>
<!-- Nav end-->