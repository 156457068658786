import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location, LocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public get BaseUrl() :string {
    return 'http://93.51.51.49:6875/api';
    
  }
  // BaseUrl: string = 'http://localhost:21242/api';
  // BaseUrl: string = '../api';
  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    //   'Access-Control-Allow-Origin' : '*',
    //   'Access-Control-Allow-Methods' : '*',
    //   'Access-Control-Allow-Headers' : '*',
      //'Pr_Id': '10'
      // 'Authorization': 'jwt-token'
    }),
    params: {}
  };
  public get HttpOptions() {
    const token = 'Token WTuBSnTiEpkjAM6mY0gE3GGlubpbm1zz:uTHlqBJxhm30Ms4j1NmAeO1OxdSHO2w5';
    if(this._httpOptions.headers.has('Authorization'))
      this._httpOptions.headers = this._httpOptions.headers.delete('Authorization');
    this._httpOptions.headers = this._httpOptions.headers.append('Authorization', token);

    return this._httpOptions;
  }
  public http: HttpClient;

  public location: Location;
  public locationStrategy: LocationStrategy;

  constructor(public injector: Injector) {
    if (!this.location) {
      this.location = this.injector.get(Location);
    }
    if (!this.locationStrategy) {
      this.locationStrategy = this.injector.get(LocationStrategy);
    }

    if (!this.http) {
      this.http = this.injector.get(HttpClient);
    }
  }
}
