<!--header start-->
<section class="app2 header overflow-unset" id="home">
  <div class="app2-header bg">
      <div class="container">
          <div class="row">
              <div class="col-xl-7 col-md-6 margin_extra">
                  <div class="center-text">
                      <div>
                          <!-- <h6 class="header-top-line"># app landing page</h6> -->
                          <div class="header-text">
                              <h1>Gestisci i tuoi <span class="bold-text">ordini</span> con un click</h1>
                          </div>
                          <div class="header-sub-text">
                              <p class="text-white p_big">
                                Ordini telefonici? Ordini Whatsapp? Ordini su Facebook? <br />
                                <b>Il tuo Conta</b> è il punto unico
                                di raccolta e gestione di tutti i tuoi ordini.
                              </p>
                          </div>
                          <div>
                            <p class="text-white p_big">
                                Provalo subito con il nostro sito demo. E' facilissimo. Entra ed inserisci le credenziali.
                                <br/>
                                Username: <b>demo@demo.it</b> <br />
                                Password: <b>demodemo</b>
                            </p>
                          </div>
                          <br />
                          <div class="link-horizontal">
                              <ul>
                                  <!-- <li>
                                      <a class="btn btn-default btn-white">get app
                                          now</a>
                                  </li> -->
                                  <li>
                                      <a href="https://iltuocontademo.web.app/" target="_blank" class="btn btn-default primary-btn transparent">Prova il sito demo</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-5 col-md-6">
                  <div class="img-mobile set-abs">
                      <img src="../assets/images/app_landing2/h2-mobile.png" alt="" class="headaer-image">

                  </div>
                  <!-- <div class="wave-orange"></div> -->
              </div>
          </div>
      </div>
      <div class="wave"></div>
  </div>
</section>
<!--header end-->