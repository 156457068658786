import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-modern',
  templateUrl: './modern.component.html',
  styleUrls: ['./modern.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModernComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private title: Title) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }

  screenshots_a = [
    { img: "assets/images/ss/a_01.jpg" },
    { img: "assets/images/ss/a_02.jpg" },
    { img: "assets/images/ss/a_03.jpg" },
    { img: "assets/images/ss/a_04.jpg" },
  ]

  screenshots_d = [
    { img: "assets/images/ss/d_01.png" },
    { img: "assets/images/ss/d_02.png" },
    { img: "assets/images/ss/d_03.png" },
    { img: "assets/images/ss/d_04.png" },
  ]
  screenshotscarouselOptions_d = {
    items: 1,
    margin: 55,
    autoHeight: true,
    nav: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        360: {
            items: 2,
            margin: 10
        },
        480: {
            margin: 15
        },
        576: {
            margin: 15
        },
        1000: {
            items: 1
        }
    }
  }
}
