<!--copyright css start-->
<div class="app2 copyright">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="center-content">
                    <div>
                        <h6 class="copyright-text text-white">Copyright © 2023, Development by Esteban Lombardo & Alessandro Strazzari</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- copyright css end-->
<app-tap-to-top></app-tap-to-top>