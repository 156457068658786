import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
  })
  export class ManualiService extends BaseService {
    public loading: boolean;
    constructor(public injector: Injector) {
        super(injector);
    }
    public GetPage(id:number) : Observable<any> {
    
        //this.HttpOptions.params = params;
        return this.http.get(`${this.BaseUrl}/pages/${id}`, this.HttpOptions)
        .pipe(
          map((response:any) => {
            return response;
            // var res = OutputStrip.Factory(response);
            // // var res : any[] = [];
            // // response.forEach(x => {
            // //   res.push(Object.assign({}, x));
            // // });
            // return res;
            //
          })
        );
      }
    public GetPages() : Observable<any> {
    
        //this.HttpOptions.params = params;
        return this.http.get(`${this.BaseUrl}/pages`, this.HttpOptions)
        .pipe(
          map((response:any) => {
            return response;
            // var res = OutputStrip.Factory(response);
            // // var res : any[] = [];
            // // response.forEach(x => {
            // //   res.push(Object.assign({}, x));
            // // });
            // return res;
            //
          })
        );
      }
}