import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-services',
  templateUrl: './modern-services.component.html',
  styleUrls: ['./modern-services.component.scss']
})
export class ModernServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  services = [
    {
      img: "assets/images/app_landing2/service/2-1.png",
      title: "Gestione ordini",
      description: "Ricevi automaticamente tutti gli ordini. Con un click puoi stampare le varie etichette per i clienti e il tuo riepilogo dei prodotti"
    },
    {
      img: "assets/images/app_landing2/service/2-2.png",
      title: "Gestione prodotti",
      description: "Puoi configurare prezzi, offerte, disponibilità e categorizzarli per rendere più semplice la ricerca"
    },
    {
      img: "assets/images/app_landing2/service/2-3.png",
      title: "Notifiche",
      description: "Ad ogni ordine viene inviata una notifica via mail sia a te che al tuo cliente con i dettagli della spedizione o del ritiro"
    },
    {
      img: "assets/images/app_landing2/service/2-4.png",
      title: "Consegna a domicilio",
      description: "Configura le zone e i giorni di consegna. Il sistema calcolerà tutto per te organizzandoti il giro di consegne"
    },
    {
      img: "assets/images/app_landing2/service/2-5.png",
      title: "Ritiro in negozio",
      description: "Con la funzione 'multistore' il cliente può decidere dove e a che ora venire a ritirare il suo ordine"
    },
    {
      img: "assets/images/app_landing2/service/2-6.png",
      title: "Web app",
      description: "L'app non è da installare. Basta un QR code o un indirizzo web. E' compatibile con tutti i dispositivi sia mobile che desktop"
    }
  ]

}
