import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManualiComponent } from './layouts/manuali/manuali.component';
import { ModernComponent } from './layouts/modern/modern.component'
export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: ModernComponent,
    data: {
      title: "Il Tuo Conta - Gestisci i tuoi ordini con un click"
    }
  },
  {
    path: 'manuali',
    component: ManualiComponent,
    data: {
      title: "Il Tuo Conta - Manuali"
    }
  },
  {
    path: 'manuali/:id',
    component: ManualiComponent,
    data: {
      title: "Il Tuo Conta - Manuali"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
