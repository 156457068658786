<app-modern-nav></app-modern-nav>

<app-header></app-header>
<section class="app2 services p-t-0" id="services">
    <!-- <div class="animated-bg"><i></i><i></i><i></i></div> -->
        <div class="container">
            <div class="row" *ngIf="false">
                <div class="col-md-10 offset-md-1">
                    <div class="title title2">
                        <!-- <img src="assets/images/logo/2.png" alt="" class="img-fluid title-img"> -->
                        <!-- <h6 class="font-primary borders main-text text-uppercase"><span>funzioni</span></h6> -->
                        <div class="sub-title">
                            <h2 class="title-text text-capitalize text-center">Manuali </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bookstack">
                <main class="content-wrap">
                    <!-- <img src="assets/images/logo/2.png" alt="" class="img-fluid title-img"> -->
                    <h1 class="break-text" style="text-align: center;">Manuali</h1>
                    <div refs="entity-search@contentView" class="book-content" *ngIf="pages.length > 0">
                        <p class="text-muted"></p>
                        <div class="entity-list book-contents" >
                            <a
                            (click)="GoToPage(data)"
                            class="page entity-list-item"
                            data-entity-type="page"
                            data-entity-id="70"
                            *ngFor="let data of pages"
                            >
                            <span role="presentation" class="icon text-page"
                                ><svg
                                class="svg-icon"
                                data-icon="page"
                                role="presentation"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
                                ></path></svg></span>
                            <div class="content">
                                <h4 class="entity-list-item-name break-text">{{data.name}}</h4>
                                <div class="entity-item-snippet">
                                <!-- <p class="text-muted break-text">
                                    Per pulire l'ordine&nbsp; andare in Home... ... quindi cliccare
                                    su&nbsp; [Pulisci ordine], pulsante...
                                </p> -->
                                </div>
                            </div>
                            </a>
                            
                        </div>
                    </div>
                    <div component="page-display" option:page-display:page-id="76" class="page-content clearfix" *ngIf="single_page">
                        <div dir="auto" *ngIf="single_page" >
                            <h2 class="break-text" id="bkmrk-page-title">{{single_page.name}}</h2>
                            <div [innerHTML]="single_page.html"></div>
                         </div>
                    </div>
                </main>
            </div>
            <div class="col-lg-4 col-md-6 service-container" *ngIf="false">
            <!-- <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of pages"> -->
                
                <div class="service text-center">
                    <!-- <div class="img-block">
                        <img [src]="data.img" alt=""
                             class="service-img img-fluid">
                    </div> -->
                    <div class="service-feature">
                        <h4 class="feature-text text-center">{{data.name}}</h4>
                        <!-- <p class="text-center">{{data.description}}</p> -->

                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6 service-container" *ngIf="single_page"> -->
            <div class="col-lg-4 col-md-6 service-container" *ngIf="false">
                
                <div class="service text-center">
                    <!-- <div class="img-block">
                        <img [src]="data.img" alt=""
                             class="service-img img-fluid">
                    </div> -->
                    <div class="service-feature">
                        <h4 class="feature-text text-center">{{single_page.name}}</h4>
                        <!-- <p class="text-center">{{data.description}}</p> -->
                        
                    </div>
                </div>
                <div [innerHTML]="single_page.html"></div>
            </div>

        </div>
</section>  
<!-- <pre>{{pages | json}}</pre> -->

<!-- <app-modern-team></app-modern-team> -->
<app-modern-subscribe></app-modern-subscribe>
<app-modern-footer></app-modern-footer>