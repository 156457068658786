<!--team start-->
<section class="app2 team p-t-0">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title2">
                    <img src="assets/images/logo/2.png" alt="" class="img-fluid title-img">
                    <h6 class="font-primary borders main-text"><span></span></h6>
                    <div class="sub-title">
                        <h2 class="title-text text-capitalize text-center">Chi ci ha scelto</h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="teamcarouselOptions" class="team-slider">
                    <ng-container *ngFor="let team of teams">
                        <ng-template carouselSlide class="item">
                            <div class="team-container">
                                <img [src]="team.img" alt="" class="img-fluid members">
                                <div class="text-center">
                                    <h5 class="name">{{team.name}}</h5>
                                    <h6 class="post ">{{team.designation}}</h6>
                                    <p class="team-para">{{team.description}}</p>
                                    <div style="margin-top:20px;">
                                        <a [href]="team.url" target="_blank" class="btn btn-default primary-btn">Apri App</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--team end-->