import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-subscribe',
  templateUrl: './modern-subscribe.component.html',
  styleUrls: ['./modern-subscribe.component.scss']
})
export class ModernSubscribeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
