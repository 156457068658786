import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-pricing',
  templateUrl: './modern-pricing.component.html',
  styleUrls: ['./modern-pricing.component.scss']
})
export class ModernPricingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  pricing = [
    { 
      icon:"rocket-ship",
      package:"Partenza",
      feature1:"Installazione e configurazione",
      feature2:"Personalizzazione",
      feature3:"1 Mese gratis",
      price:"500",
      currency:"€",
      post_price:" una tantum"
      // btn:"purchase"
    },
    {
      icon:"diamond",
      package:"A regime",
      feature1:"Assistenza tecnica",
      feature2:"Formazione",
      feature3:"dal 2° mese in poi",
      price:"5",
      currency:"",
      post_price:" % per ogni ordine"
      // btn:"purchase"
    },
    // {
    //   icon:"pie-chart",
    //   package:"Business",
    //   feature1:"Easy Installations",
    //   feature2:"Unlimited support",
    //   feature3:"Free Forever",
    //   price:"99",
    //   btn:"purchase"
    // }
  ]
  
  pricingcarouselOptions= {
    items: 2,
    margin: 30,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    dots: false,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        576: {
            items: 2,
            margin: 10
        },
        991: {
            items:2,
            margin: 15
        },
        992: {
            items:2,
            margin: 15
        },
        1000: {
            items: 2
        }
    }
  }
}
